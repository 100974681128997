<template>
  <PxHeader :activeSearchMobile="false" />

  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else>
    <publish-button />
    <img
      v-if="record"
      class="hidden md:flex object-cover min-h-contain-register w-full"
      :src="loadImg(record.img_head_url)"
      alt=""
    />
    <div class="container-bg-register-desktop">
      <div
        class="bg-agrogo-green-2 bg-agrogo-green-2-transparent sizeMinAllView"
      >
        <div class="container container-forms pb-8 md:pt-8">
          <div class="flex flex-col">
            <div>
              <div class="flex mb-3">
                <router-link
                  class="text-base text-white hover:text-agrogo-yellow-1 font-nexa font-bold md:text-2xl"
                  to="/inicio-de-sesion"
                  >Ingresar</router-link
                ><span class="text-agrogo-gray-2 font-black mx-3 md:text-2xl"
                  >|</span
                ><router-link
                  class="text-base text-agrogo-yellow-1 font-nexa font-bold md:text-2xl"
                  to="/registro"
                  >Registrarse</router-link
                >
              </div>
              <p
                class="text-xs text-white font-nexa font-normal text-left mb-8 md:text-lg"
              >
                Registrate y publica tu primera Finca
              </p>
            </div>
          </div>
          <div class="">
            <div class="flex w-full h-full pb-4">
              <div class="flex items-center">
                <p
                  class="text-xs text-white font-nexa font-regular text-left mr-3 md:text-lg"
                >
                  Tipo de persona
                </p>
                <div
                  class="flex items-center mr-3"
                  v-for="(item, index) in options"
                  :key="index"
                >
                  <input
                    v-model="selected"
                    :value="item.value"
                    :id="'person' + index"
                    name="tipo_persona"
                    type="radio"
                    class="custom-radio-test"
                    @change="change"
                  />
                  <label
                    :for="'person' + index"
                    class="text-white text-xs font-dinpro font-normal block ml-2 hover:text-agrogo-yellow-1 md:text-lg"
                  >
                    {{ item.text }}
                  </label>
                </div>
              </div>
            </div>
            <natural-person-form v-if="isNaturalPerson" />
            <legal-person-form v-else />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
// Components
import PxHeader from "@/components/header/PxHeader";
import NaturalPersonForm from "./components/NaturalPersonForm";
import LegalPersonForm from "./components/LegalPersonForm";
import PublishButton from "@/components/publishButton/PublishButton";
export default {
  name: "Register",
  inject: ["provider"],
  components: {
    NaturalPersonForm,
    LegalPersonForm,
    PxHeader,
    PublishButton,
  },
  data() {
    return {
      selected: "1",
      options: [
        { text: "Natural", value: "1" },
        { text: "Juridica", value: "2" },
      ],
      isNaturalPerson: true,
      repository: this.provider.publishFarmRepository,
      record: null,
      isBusy: true,
    };
  },
  methods: {
    change() {
      const me = this;
      me.selected == "1"
        ? (me.isNaturalPerson = true)
        : (me.isNaturalPerson = false);
    },
    async loadData() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllNames(
          "NATURAL_PERSON_REGISTRATION",
          dataAditional
        );
        me.record = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    loadImg(url) {
      let img = null;
      img = url
        ? `${API_ROUTES.image.get}${url}`
        : require("@/assets/images/paisajeaboutusdesktop.jpg");
      return img;
    },
  },
  mounted() {
    const me = this;
    window.scroll(0, 0);
    me.loadData();
  },
};
</script>
