<template>
  <form @submit.prevent="saveData">
    <input type="hidden" name="remember" value="true" />
    <div
      class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left md:text-base"
    >
      *Ingresar NIT SIN dígito de verificación.
    </div>
    <div class="rounded-md shadow-sm align-middle">
      <label
        for="company_name"
        class="text-xs text-white font-nexa block ml-2 mb-1 text-left md:text-base"
        >Razón social</label
      >
      <div class="relative">
        <div
          class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
        >
          <span class="icon-Perfil_2 text-agrogo-black-1 text-lg"></span>
        </div>
        <input
          v-model="$v.formData.razon_social.$model"
          @blur="$v.formData.razon_social.$touch()"
          id="razon_social"
          name="razon_social"
          type="text"
          class="input-login"
          :class="{ 'error-form': $v.formData.razon_social.$error }"
        />
      </div>
      <div v-if="messageEmailPhone != null">
        <p
          v-if="messageEmailPhone.msg.razon_social"
          class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
        >
          {{ messageEmailPhone.msg.razon_social[0] }}
        </p>
      </div>
      <p
        class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
        v-if="$v.formData.razon_social.$error"
      >
        {{ messageValidation($v.formData.razon_social) }}
      </p>
      <p
        class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
        v-else
      >
        &nbsp;
      </p>
      <div class="md:flex lg:flex">
        <div class="md:w-1/2 lg:w-1/2 md:pr-1 lg:pr-1">
          <label
            for="nit"
            class="text-xs text-white font-nexa block ml-2 mb-1 text-left md:text-base"
            >NIT</label
          >
          <div class="relative">
            <div
              class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
            >
              <span class="icon-cedula text-agrogo-black-1 text-lg"></span>
            </div>
            <input
              v-model="$v.formData.NIT.$model"
              @blur="$v.formData.NIT.$touch()"
              id="NIT"
              name="NIT"
              type="number"
              class="input-login"
              :class="{ 'error-form': $v.formData.NIT.$error }"
            />
          </div>
          <div v-if="messageEmailPhone != null">
            <p
              v-if="messageEmailPhone.msg.NIT"
              class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
            >
              {{ messageEmailPhone.msg.NIT[0] }}
            </p>
          </div>
          <p
            class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
            v-if="$v.formData.NIT.$error"
          >
            {{ messageValidation($v.formData.NIT) }}
          </p>
          <p
            class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
            v-else
          >
            &nbsp;
          </p>
        </div>
        <div class="md:w-1/2 lg:w-1/2 md:pl-1 lg:pl-1">
          <label
            for="email-address"
            class="text-xs text-white font-nexa block ml-2 mb-1 text-left md:text-base"
            >Email</label
          >
          <div class="relative">
            <div
              class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
            >
              <span class="icon-mail text-agrogo-black-1 text-lg"></span>
            </div>
            <input
              v-model="$v.formData.email.$model"
              @blur="$v.formData.email.$touch()"
              id="email"
              name="email"
              type="email"
              class="input-login"
              :class="{ 'error-form': $v.formData.email.$error }"
            />
          </div>
          <div v-if="messageEmailPhone != null">
            <p
              v-if="messageEmailPhone.msg.email"
              class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
            >
              {{ messageEmailPhone.msg.email[0] }}
            </p>
          </div>
          <p
            class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
            v-if="$v.formData.email.$error"
          >
            {{ messageValidation($v.formData.email) }}
          </p>
          <p
            class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
            v-else
          >
            &nbsp;
          </p>
        </div>
      </div>
      <label
        for="indicativo_telephone"
        class="text-xs text-white font-nexa block ml-2 mb-1 text-left md:text-base"
        >Teléfono
        <span class="icon-whatsapp"></span>
        Whatsapp</label
      >
      <div class="flex">
        <div class="relative w-2/5 md:w-3/12 mr-2">
          <img :src="img" class="img-code" />
          <v-select
            :options="codes"
            v-model="selected"
            :reduce="(option) => option.id"
            class="style-chooser"
            label="phone_code"
          >
            <template #option="{ phone_code, path_img_iso_large, name }">
              <div class="flex items-center justify-between">
                <div class="flex">
                  <img
                    :src="path_img_iso_large"
                    :alt="phone_code"
                    class="mr-2"
                  />
                  <div class="font-medium">{{ phone_code }}</div>
                </div>
                <div class="font-medium">
                  <div v-if="name.length > 20">{{ name.slice(0, 20) }}..</div>
                  <div v-else>{{ name }}</div>
                </div>
              </div>
            </template>
            <template #no-options>Sin opciones</template>
          </v-select>
        </div>
        <div class="relative w-3/5 md:w-9/12">
          <div
            class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
          >
            <span class="icon-telefono text-agrogo-black-1 text-lg"></span>
          </div>
          <input
            v-model="$v.formData.telephone.$model"
            @blur="$v.formData.telephone.$touch()"
            id="telephone"
            name="telephone"
            type="number"
            class="input-login"
            :class="{ 'error-form': $v.formData.telephone.$error }"
          />
        </div>
      </div>
      <div v-if="messageEmailPhone != null">
        <p
          v-if="messageEmailPhone.msg.telephone"
          class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
        >
          {{ messageEmailPhone.msg.telephone[0] }}
        </p>
      </div>
      <p
        class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
        v-if="$v.formData.telephone.$error"
      >
        {{ messageValidation($v.formData.telephone) }}
      </p>
      <p
        class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
        v-else
      >
        &nbsp;
      </p>
      <label
        for="password"
        class="text-xs text-white font-nexa block ml-2 mb-1 text-left md:text-base"
        >Contraseña</label
      >
      <div class="relative">
        <div
          class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
        >
          <span class="icon-password text-agrogo-black-1 text-base"></span>
        </div>
        <input
          v-model="$v.formData.password.$model"
          @blur="$v.formData.password.$touch()"
          id="password"
          name="password"
          type="password"
          class="input-login"
          :class="{ 'error-form': $v.formData.password.$error }"
        />
      </div>
      <p
        class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
        v-if="$v.formData.password.$error"
      >
        {{ messageValidation($v.formData.password) }}
      </p>
      <p
        class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
        v-else
      >
        &nbsp;
      </p>
      <label
        for="password"
        class="text-xs text-white font-nexa block ml-2 mb-1 text-left md:text-base"
        >Confirmar contraseña</label
      >
      <div class="relative">
        <div
          class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
        >
          <span class="icon-password_2 text-agrogo-black-1 text-lg"></span>
        </div>
        <input
          v-model="$v.formData.password_confirmation.$model"
          @blur="$v.formData.password_confirmation.$touch()"
          id="password_confirmation"
          name="password_confirmation"
          type="password"
          class="input-login"
          :class="{ 'error-form': $v.formData.password_confirmation.$error }"
        />
      </div>
      <p
        class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
        v-if="$v.formData.password_confirmation.$error"
      >
        {{ messageValidation($v.formData.password_confirmation) }}
      </p>
      <p
        class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
        v-else
      >
        &nbsp;
      </p>
    </div>
    <!-- <div class="flex">
      <div class="flex items-center">
        <input
          v-model="$v.formData.verify_whatsapp.$model"
          @blur="$v.formData.verify_whatsapp.$touch()"
          :true-value="true"
          :false-value="false"
          id="verify_whatsapp"
          name="verify_whatsapp"
          type="checkbox"
          class="custom-checkbox-test"
        />
      </div>
      <div class="flex items-end text-left">
        <label
          for="verify_whatsapp"
          class="text-white text-xs font-nexa font-normal block ml-3 md:text-base md:pr-32"
        >
          <span
            v-if="$v.formData.verify_whatsapp.$invalid"
            class="text-agrogo-yellow-2"
            >*</span
          >
          Acepta recibir mensajes por parte de
          <span class="font-nexa font-black">AGRO GO</span>
          vía Whatsapp
        </label>
      </div>
    </div> -->
    <div class="flex pt-2 mb-4">
      <div class="flex items-center">
        <input
          v-model="$v.formData.verify_tc.$model"
          @blur="$v.formData.verify_tc.$touch()"
          :true-value="true"
          :false-value="false"
          id="verify_tc"
          name="verify_tc"
          type="checkbox"
          class="custom-checkbox-test"
        />
      </div>
      <div class="flex items-end text-left">
        <label
          for="verify_tc"
          class="text-white text-xs font-dinpro font-normal block ml-3 md:text-base md:pr-18"
        >
          <span
            v-if="$v.formData.verify_tc.$invalid"
            class="text-agrogo-yellow-2"
            >*</span
          >
          <span
            >Acepto
            <router-link
              class="underline underline-offset-4"
              to="/terminos-y-condiciones"
              target="_blank"
            >
              términos y condiciones </router-link
            >,
            <router-link
              class="underline underline-offset-4"
              to="/politicas-de-tratamiento-de-datos-personales"
              target="_blank"
            >
              políticas de privacidad
            </router-link></span
          >
        </label>
      </div>
    </div>
    <div class="pb-4">
      <Recaptcha
        @dataResponse="$v.formData.token_recaptcha.$model = $event"
        @blur="$v.formData.token_recaptcha.$touch()"
      />
    </div>
    <div>
      <button
        type="submit"
        class="btn-login flex justify-center items-center"
        :disabled="isBusy || $v.$invalid"
      >
        <div class="loader" v-if="isBusy" />
        Registrar cuenta
      </button>
    </div>
    <div class="mt-4 text-left bg-agrogo-yellow-1 p-2" v-if="errors.length > 0">
      <ul class="ul">
        <li
          class="text-agrogo-green-2 font-nexa text-xs mb-1"
          v-for="(item, index) in errors"
          :key="index"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </form>
</template>

<script>
import validationMixin from "@/mixins/validationMixin";
import loginRegisterMixin from "@/mixins/loginRegisterMixin";
import Recaptcha from "@/components/recaptcha/Recaptcha";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  sameAs,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  inject: ["provider"],
  mixins: [validationMixin, loginRegisterMixin],
  data() {
    return {
      $v: useVuelidate(),
      formData: {
        NIT: "",
        razon_social: "",
        telephone: "",
        code_area_id: 1,
        type_user_id: 1,
        verify_tc: false,
        verify_whatsapp: true,
        password: "",
        password_confirmation: "",
        email: "",
        token_recaptcha: null,
      },
      isBusy: false,
      repository: this.provider.authRepository,
      codes: [],
    };
  },
  components: {
    Recaptcha,
  },
  validations() {
    return {
      formData: {
        NIT: {
          required,
          numeric,
          minLength: minLength(9),
          maxLength: maxLength(9),
        },
        razon_social: {
          required,
        },
        telephone: {
          required,
          numeric,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
        password: {
          required,
        },
        password_confirmation: {
          required,
          sameAsPassword: sameAs(this.formData.password),
        },
        email: {
          required,
          email,
        },
        verify_whatsapp: {
          checked(val) {
            return val;
          },
        },
        verify_tc: {
          checked(val) {
            return val;
          },
        },
        token_recaptcha: {
          required,
        },
      },
    };
  },
  async mounted() {
    const me = this;
    await me.loadCodeArea();
  },
};
</script>

<style></style>
