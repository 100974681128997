<template>
  <div class="flex justify-center">
    <VueRecaptcha
      :sitekey="siteKey"
      :load-recaptcha-script="true"
      @verify="handleSuccess"
      @error="handleError"
    ></VueRecaptcha>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "ReCaptcha",
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      siteKey: process.env.VUE_APP_SITE_KEY_RECAPTCHA,
    };
  },
  methods: {
    /**
     * Represents the event error the VueRecaptcha Google component.
     * @param {object} response - response null of recaptcha Google.
     */
    handleError(response) {
      this.$emit("dataResponse", response);
    },

    /**
     * Represents the event success the component VueRecaptcha Google.
     * @param {string} response - The token of recaptcha Google.
     */
    async handleSuccess(response) {
      this.$emit("dataResponse", response);
    },
  },
};
</script>
